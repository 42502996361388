
















































































































import Vue from 'vue'

// components
import CommissionQuotaAttainmentGroups from '@/components/dashboard/CommissionQuotaAttainmentGroups.vue'
import PivotBarChart from '@/components/charts/PivotBarChart.vue'

// utils
import { AmountField, amountFields } from '@/components/charts/chart-settings'

// types
import { CommissionPayPeriod, ID } from '@/types'

export default Vue.extend({
  metaInfo: {
    title: 'By Period Charts - Dashboard',
  },
  components: {
    CommissionQuotaAttainmentGroups,
    PivotBarChart,
  },
  props: {
    period: {
      type: Number,
      default: new Date().getFullYear(),
    },
  },
  data: () => ({
    amountFieldId: 'postedAmount',
    amountFields: amountFields,

    cumulative: false,
  }),
  computed: {
    amountField(): AmountField {
      return (
        this.amountFields.find(z => z.id === this.amountFieldId) ||
        amountFields[0]
      )
    },
  },
  methods: {
    getYAxisAnnotations(amount: number): YAxisAnnotations[] {
      if (!amount) return []
      return [
        {
          y: amount,
          borderColor: (
            this.$vuetify.theme.currentTheme.primary || 'black'
          ).toString(),
          opacity: 1,
          strokeDashArray: 8,
        },
      ]
    },
    getCategories(commissionQuotaPeriodId: ID): ID[] {
      return this.$store.getters
        .getCommissionPayPeriodsByCommissionQuotaPeriodId(
          commissionQuotaPeriodId
        )
        .map((z: CommissionPayPeriod) => z.id)
    },
    columnFinder(id: ID): string {
      return this.$store.getters.getCommissionComponentById(id).abbr
    },
    rowItemFinder(id: ID): CommissionPayPeriod {
      return this.$store.getters.getCommissionPayPeriodById(id)
    },
    rowFinder(id: ID): string {
      return this.rowItemFinder(id).abbr
    },
    sortValueFn(data: never[], id: number) {
      return this.rowItemFinder(id).startDate
    },
  },
})
