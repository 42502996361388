var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('commission-quota-attainment-groups',{staticClass:"pt-16 pt-sm-0",attrs:{"period":_vm.period,"cc-cols":"12","cc-sm":"12","cc-md":"6","cc-lg":"6","cc-xl":"4"},scopedSlots:_vm._u([{key:"top-right",fn:function(){return [_c('v-switch',{staticClass:"mt-0 pt-0",attrs:{"label":"Cumulative","hide-details":""},model:{value:(_vm.cumulative),callback:function ($$v) {_vm.cumulative=$$v},expression:"cumulative"}}),_c('v-select',{staticClass:"ml-4",staticStyle:{"width":"128px"},attrs:{"label":"Amount","items":_vm.amountFields,"item-value":"id","item-text":"label","solo":"","hide-details":""},model:{value:(_vm.amountFieldId),callback:function ($$v) {_vm.amountFieldId=$$v},expression:"amountFieldId"}})]},proxy:true},{key:"commission-component-group",fn:function(ref){
var group = ref.group;
var item = ref.item;
return [_c('v-card',{staticClass:"pa-3",attrs:{"outlined":""}},[_c('pivot-bar-chart',{attrs:{"where":{
          companyId: group.company.id,
          salesAreaId: group.salesArea.id,
          salespersonId: group.salesperson.id,
          commissionQuotaPeriodId: group.commissionQuotaPeriod.id,
          currency: group.currency,
          commissionComponentGroupId: item.commissionComponentGroup.id,
        },"title":(item.commissionComponentGroup.abbr + ' Total').toUpperCase(),"categories":_vm.getCategories(group.commissionQuotaPeriod.id),"amount-field":_vm.amountFieldId,"amount-label":((_vm.amountField.label) + " (" + (group.currency) + ")"),"cumulative":_vm.cumulative,"row-field":"commissionPayPeriodId","row-label":"Commission Pay Period","row-finder":_vm.rowFinder,"column-field":"commissionComponentId","column-finder":_vm.columnFinder,"sort-value-fn":_vm.sortValueFn,"sort-desc":false,"horizontal":false,"stacked":true,"show-data-labels":"","yaxis-annotations":_vm.getYAxisAnnotations(
            item.commissionQuotaAmount /
              (_vm.cumulative
                ? 1
                : _vm.getCategories(group.commissionQuotaPeriod.id).length)
          )}})],1)]}},{key:"default",fn:function(ref){
          var item = ref.item;
return [_c('v-card',{staticClass:"pa-3",attrs:{"outlined":""}},[_c('pivot-bar-chart',{attrs:{"where":{
          companyId: item.company.id,
          salesAreaId: item.salesArea.id,
          salespersonId: item.salesperson.id,
          commissionQuotaPeriodId: item.commissionQuotaPeriod.id,
          currency: item.currency,
          commissionComponentId: item.commissionComponent.id,
        },"title":(
            item.commissionComponent.abbr +
            ' / ' +
            item.commissionComponent.group.abbr
          ).toUpperCase(),"categories":_vm.getCategories(item.commissionQuotaPeriod.id),"amount-field":_vm.amountFieldId,"amount-label":((_vm.amountField.label) + " (" + (item.currency) + ")"),"cumulative":_vm.cumulative,"row-field":"commissionPayPeriodId","row-label":"Commission Pay Period","row-finder":_vm.rowFinder,"sort-value-fn":_vm.sortValueFn,"sort-desc":false,"horizontal":false,"stacked":true,"show-data-labels":"","yaxis-annotations":_vm.getYAxisAnnotations(
            item.commissionQuotaAmount /
              (_vm.cumulative
                ? 1
                : _vm.getCategories(item.commissionQuotaPeriod.id).length)
          )}})],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }